import {  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

import { ApiService } from '@app/shared/services/api.service';

@Injectable()
export class CustomerService {

  private customerApiUrl = 'customers';

  constructor (
    private apiService: ApiService,
  ) {}

  /** Get Customer details*/
  getCustomerInfo(customerId): Observable<any> {
    const url = `${this.customerApiUrl}/${customerId}/`;
    return this.apiService.get(url).pipe(
      map(
        data => {
          return data;
        }
      ));
  }

  updateCustomerInfo(values) {
    if (values.id) {
      const url = `${this.customerApiUrl}/${values.id}/`;
      return this.apiService
        .patch(url, values).pipe(
          map(data => {
            return data;
          })
        );
    }
  }

}
