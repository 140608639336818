import {  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

import { ApiService } from '@app/shared/services/api.service';

@Injectable()
export class ShipmentService {

  private shipmentApiUrl = 'shipments';
  private invoiceApiUrl = 'invoice';
  private billApiUrl = 'bills';

  private maxAllowedPackageWeight = {
    ground: 150,
    smartpost: 20
  };

  private deletedShipmentCode = ['label-printed', 'label-created', 'shipment-created'];

  constructor (
    private apiService: ApiService,
  ) {}

  /** Get Dashboard Statistics*/
  getDashboardStats(): Observable<any> {
    const url = 'dashboard-summary';
    return this.apiService.get(url).pipe(
      map(
        data => {
          return data;
        }
      ));
  }

  /** Get Shipment list*/
  getShipmentList(params): Observable<any> {
    const url = `${this.shipmentApiUrl}`;
    return this.apiService.get(url, params).pipe(
      map(
        data => {
          return data;
        }
      ));
  }

  /** Get Shipment*/
  getShipment(shipmentId): Observable<any> {
    const url = `${this.shipmentApiUrl}/${shipmentId}`;
    return this.apiService.get(url).pipe(
      map(
        data => {
          return data;
        }
      ));
  }

  /** Post Shipment */
  postShipmentList(values): Observable<any> {
    const url = `create_shipment`;
    return this.apiService.post(url, values).pipe(
      map(
        data => {
          return data;
        }
      ));
  }

  /** Delete Shipment */
  deleteShipment(value) {
    const url = `delete_shipment`;
    return this.apiService.patch(url, value).pipe(
      map(response => {
        return response;
      }));
  }

  /** Print shipment label*/
  printShipmentLabel(value): Observable<any> {
    const url = `print_shipment_label`;
    return this.apiService.patch(url, value).pipe(
      map(data => {
        return data;
      }));
  }

  /** Get Shipment*/
  getUploadEstimateProgress(): Observable<any> {
    const url = `upload_estimate_progress`;
    return this.apiService.get(url).pipe(
      map(
        data => {
          return data;
        }
      ));
  }

  /** Get Shipment*/
  getUploadCreateProgress(): Observable<any> {
    const url = `upload_create_progress`;
    return this.apiService.get(url).pipe(
      map(
        data => {
          return data;
        }
      ));
  }

  /** Get Invoice list*/
  getInvoiceList(params): Observable<any> {
    const url = `${this.invoiceApiUrl}`;
    return this.apiService.get(url, params).pipe(
      map(
        data => {
          return data;
        }
      ));
  }

  /** Get Invoice */
  getInvoice(invoiceId): Observable<any> {
    const url = `${this.invoiceApiUrl}/${invoiceId}`;
    return this.apiService.get(url).pipe(
      map(
        data => {
          return data;
        }
      ));
  }

  /** Get Invoice Bill list*/
  getInvoiceBillList(invoiceId, params): Observable<any> {
    const url = `${this.invoiceApiUrl}/${invoiceId}/bills`;
    return this.apiService.get(url, params).pipe(
      map(
        data => {
          return data;
        }
      ));
  }

  /** Delete Invoice */
  deleteInvoice(invoiceId) {
    const url = `${this.invoiceApiUrl}/${invoiceId}`;
    return this.apiService
      .delete(url).pipe(
        map(response => {
          return response;
        })
      );
  }

  /** Get Bills */
  getBills(params): Observable<any> {
    const url = `${this.billApiUrl}`;
    return this.apiService.get(url, params).pipe(
      map(
        data => {
          return data;
        }
      ));
  }

  /** Get Country state list*/
  getCountryStateList(): Observable<any> {
    const url = 'country_state_list';
    return this.apiService.get(url).pipe(
      map(
        data => {
          return data;
        }
      ));
  }

  /** Find estimated SIF Cost*/
  findEstimatedSIFCost(values): Observable<any> {
    const url = 'estimate_cost';
    return this.apiService.post(url, values).pipe(
      map(
        data => {
          return data;
        }
      ));
  }

  /** Get Shipstation list*/
  getShipStations(): Observable<any> {
    const url = 'shipstation-stores';
    return this.apiService.get(url).pipe(
      map(
        data => {
          return data;
        }
      ));
  }

  /** Get Shipstation Orders list*/
  getShipStationOrders(params): Observable<any> {
    const url = 'shipstation-shipments';
    return this.apiService.get(url, params).pipe(
      map(
        data => {
          return data;
        }
      ));
  }

  /** Get Shipment methods*/
  getShipmentMethods(): Observable<any> {
    const url = 'shipment_methods';
    return this.apiService.get(url).pipe(
      map(
        data => {
          return data;
        }
      ));
  }

  /** Get Signature type */
  getSignatureType(): Observable<any> {
    const url = 'signature_type';
    return this.apiService.get(url).pipe(
      map(
        data => {
          return data;
        }
      ));
  }

  /** Get Shipment status*/
  getShipmentStatus(): Observable<any> {
    const url = 'shipment_status';
    return this.apiService.get(url).pipe(
      map(
        data => {
          return data;
        }
      ));
  }

  getShipmentOrder(shipmentId): Observable<any> {
    const url = `${this.shipmentApiUrl}/${shipmentId}`;
    return this.apiService.get(url).pipe(
      map(
        data => {
          return data;
        }
      ));
  }

  importShipment(values) {
    const url = `upload-shipments`;
    const formData = new FormData();
    for (const key in values) {
      if (key) {
        if (values[key] && typeof values[key] === 'object' && values[key].constructor === Object) {
          for (const k in values[key]) {
            if (k) {
              formData.append(key + '.' + k, values[key][k]);
            }
          }
        } else if (values[key] && values[key].constructor === Array) {
          formData.append(key, JSON.stringify(values[key]));
        } else {
          formData.append(key, values[key]);
        }
      }
    }
    return this.apiService
      .postFile(url, formData).pipe(
        map(data => {
          return data;
        })
      );
  }

  getImportShipment() {
    const url = `upload-shipments`;
    return this.apiService.get(url).pipe(
      map(
        data => {
          return data;
        }
      ));
  }

  getMaxAllowedPackageWeight() {
    return this.maxAllowedPackageWeight;
  }

  getDeletedShipmentCode() {
    return this.deletedShipmentCode;
  }

  getZipcodeDetails(params) {
    const url = 'zipcode_details';
    return this.apiService.get(url, params).pipe(
      map(
        data => {
          return data;
        }
      ));
  }

}
